import type { AutomatedSurveyQueueQuestionDetailsListDto } from "api/types";
import { Button } from "components/Button/Button";
import { useAIToolingManager } from "hooks/useAIToolingManager";
import { useBool } from "hooks/useBool";
import { NoData } from "modules/analytics/components/NoData";
import { useTranslation } from "react-i18next";

import {
  SurveyQuestionGroupAnswerCardBarChart,
  SurveyQuestionGroupAnswerCardHeader,
  SurveyQuestionGroupAnswerCardLastResponseSection,
  SurveyQuestionGroupAnswerCardRating,
} from "./SurveyQuestionGroupAnswerCard";
import { SurveyQuestionGroupAnswerSentimentModal } from "./SurveyQuestionGroupAnswerSentimentModal";

export type QuestionCategory = "other" | "community" | "service" | "building" | "feelAtHome";

interface AutomatedSurveyQuestionGroupAnswerCardProps {
  order: number;
  question: AutomatedSurveyQueueQuestionDetailsListDto;
  onClickViewDetails: () => void;
}

export function AutomatedSurveyQuestionGroupAnswerCard({
  order,
  question,
  onClickViewDetails,
}: AutomatedSurveyQuestionGroupAnswerCardProps): React.ReactNode {
  const { t } = useTranslation();
  const [isSentimentModalOpen, sentimentModalOpenHandlers] = useBool(false);
  const { isAvailable: isAIToolingAvailable } = useAIToolingManager();

  const graphData: { x: string; y: number }[] = [];
  for (let i = 1; i <= 5; i++) {
    graphData.push({ x: String(i), y: question.ratingGroupedNumber[String(i)] });
  }
  const isDataAvailable = graphData.some((dataPoint) => Boolean(dataPoint.y));

  return (
    <>
      <div className="flex w-full flex-col justify-between gap-8 rounded-lg bg-white p-4 shadow-md md:p-6">
        <SurveyQuestionGroupAnswerCardHeader
          questionTitle={question.ratingQuestionText || ""}
          category={question.questionGroupType}
          {...{ order }}
        />

        <div className="flex w-full flex-col items-center justify-center gap-2 px-10 md:flex-row md:gap-10">
          <div className="flex flex-[0.4]">
            <SurveyQuestionGroupAnswerCardRating rating={question.averageRating} />
          </div>
          {/* Chart */}
          <div className="flex flex-[0.6]">
            {isDataAvailable ? <SurveyQuestionGroupAnswerCardBarChart data={graphData} /> : <NoData error={null} />}
          </div>
        </div>

        <SurveyQuestionGroupAnswerCardLastResponseSection
          questionTitle={question.openQuestionText || ""}
          lastResponse={question.latestOpenQuestionResponse}
        />

        <div className="flex w-full flex-col justify-center gap-2 md:flex-row md:justify-end">
          {isAIToolingAvailable && isDataAvailable && (
            <Button styling="secondary" onClick={sentimentModalOpenHandlers.setTrue}>
              {t("page.automated-surveys.sentiment.btn")}
            </Button>
          )}
          {isDataAvailable && (
            <Button styling="primary" onClick={onClickViewDetails}>
              {t("component.surveys-question-group-answer.button.details")}
            </Button>
          )}
        </div>
      </div>

      {isAIToolingAvailable && (
        <SurveyQuestionGroupAnswerSentimentModal
          questionGroupId={question.questionGroupId}
          isOpen={isSentimentModalOpen}
          onClose={sentimentModalOpenHandlers.setFalse}
        />
      )}
    </>
  );
}
