import { useSessionUser } from "./Network/useSessionUser";
import { useConfig } from "./useConfig";

export const useAIToolingManager = (): { isAvailable: boolean } => {
  const sessionUser = useSessionUser();
  const { setting: isAIToolingAvailable } = useConfig("isAiToolingAvailable");

  return {
    isAvailable: isAIToolingAvailable || sessionUser.isSuperAdmin,
  };
};
