import type { ChatReplyDto } from "api/types";
import refreshIcon from "assets/icons/refresh-cw-03.svg";
import { Button } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { Capture1 } from "components/Text/Text";
import { parseISO } from "date-fns";
import { stripTime } from "helpers/date";
import { DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES, megaBytesToBytes } from "helpers/file-size";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnIntersection } from "hooks/useOnIntersection";
import { groupBy, sortBy } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ChatMessage } from "./ChatMessage";
import { SystemChatMessage } from "./SystemChatMessage";

interface ChatWindowProps {
  chatReplies: ChatReplyDto[];
  chatName?: string;
  size?: "sm" | "lg";
  canChat: boolean;
  hasMoreReplies: boolean | undefined;
  isLoadingMoreReplies: boolean;
  fetchMoreReplies: () => void;
  refreshReplies?: () => void;
  sendReply: ({ message, files }: { message: string; files: FormImage[] }) => Promise<ChatReplyDto>;
}

interface ChatWindowFormValues {
  messageValue: string;
}

const groupChatRepliesByDate = <T extends { sentAt: string }>(chatMessages: T[]): [string, T[]][] => {
  const sortedChatReplies = sortBy(chatMessages, (x) => parseISO(x.sentAt).valueOf());
  const groupedByDay = Object.entries(groupBy(sortedChatReplies, (x) => stripTime(x.sentAt).valueOf()));

  return sortBy(groupedByDay, ([date]) => Number(date));
};

export function ChatWindow({
  chatReplies,
  chatName,
  size = "lg",
  canChat,
  hasMoreReplies,
  isLoadingMoreReplies,
  refreshReplies,
  sendReply,
  fetchMoreReplies,
}: ChatWindowProps): React.ReactNode {
  const [images, setImages] = useState<FormImage[]>([]);

  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const showFlashToast = useFlashToast();
  const { addImages, removeImage, removeImages } = useImageInput({ onChange: setImages, maximumFiles: 1 });
  const { getValues, setValue, watch, register, reset } = useForm<ChatWindowFormValues>({
    defaultValues: {
      messageValue: "",
    },
  });
  const scrollRef = useRef<HTMLDivElement>(null);
  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreReplies && hasMoreReplies) {
        fetchMoreReplies();
      }
    }, [fetchMoreReplies, hasMoreReplies, isLoadingMoreReplies]),
  });

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, behavior: "smooth" });
    }
  }, [chatReplies]);

  useEffect(() => {
    register("messageValue");
  }, [register]);

  const messageValue = watch("messageValue");
  const chatMessagesByDate = useMemo(() => groupChatRepliesByDate(chatReplies), [chatReplies]);

  const onChangeContent = useCallback((value: string) => setValue("messageValue", value), [setValue]);

  const onSendReply = async () => {
    const { messageValue } = getValues();

    await sendReply({
      message: messageValue,
      files: images,
    });

    removeImages();
    reset();
  };

  const onRemoveImage = useCallback(
    (imageToBeRemoved: FormImage) => {
      removeImage(imageToBeRemoved);
    },
    [removeImage],
  );

  return (
    <div className={size === "lg" ? "h-[calc(100vh-300px)]" : "h-[700px]"}>
      <div className="flex h-full flex-col justify-between rounded-lg bg-white">
        {refreshReplies && (
          <div className="flex justify-between p-5">
            <Capture1>{t("page.user-detail.chat.title")}</Capture1>
            <Button styling="secondary" onClick={() => refreshReplies()}>
              <div className="flex items-center gap-1">
                <Icon name={refreshIcon} />
                {t("page.chats.refresh")}
              </div>
            </Button>
          </div>
        )}
        <div className="flex grow flex-col gap-4 overflow-y-auto p-5 pb-4" ref={scrollRef}>
          {hasMoreReplies && (
            <div className="p-4" ref={loaderRef}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
          {chatMessagesByDate.map(([date, chatMessages]) => (
            <div key={date.toString()} data-testid="chat-message-date-group">
              <div className="mb-4 flex items-center justify-center whitespace-nowrap">
                <Capture1 className="rounded-lg bg-grey-lightest p-2">
                  <FormattedDate format="date" date={new Date(Number(date)).toISOString()} />
                </Capture1>
              </div>
              <div className="flex flex-col gap-1">
                {chatMessages.map((chatMessage) =>
                  chatMessage.replyType?.includes("system") ? (
                    <div key={chatMessage.id} className="flex items-center justify-center whitespace-nowrap">
                      <SystemChatMessage
                        chatName={chatName}
                        chatMessage={chatMessage}
                        className="rounded-lg bg-grey-lightest p-2"
                      />
                    </div>
                  ) : (
                    <ChatMessage
                      key={chatMessage.id}
                      isOwnMessage={chatMessage.author?.id === sessionUser.id}
                      hasNextMessage={
                        chatMessages[chatMessages.indexOf(chatMessage) + 1]?.author?.id === chatMessage.author?.id
                      }
                      isRead={chatMessage.hasBeenSeenByAll}
                      user={chatMessage.author!}
                      canSeeUserDetails={sessionUser.id !== chatMessage.author?.id}
                      sentAt={chatMessage.sentAt}
                      content={chatMessage.text}
                      image={chatMessage.image}
                    />
                  ),
                )}
              </div>
            </div>
          ))}
        </div>
        <form className="rounded-b-lg bg-grey-lightest px-5 py-4">
          <CommentFieldWithAvatar
            user={sessionUser}
            value={messageValue}
            withoutTopRow
            placeholder={t("page.chat-detail.chat.input.placeholder")}
            onChange={onChangeContent}
            images={images}
            onSubmit={onSendReply}
            allowsImages
            onRemoveImage={onRemoveImage}
            onAddImages={(images) => {
              const maxMegaBytes = DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES;
              const maxBytes = megaBytesToBytes(maxMegaBytes);
              if (Array.from(images).some((x) => x.size > maxBytes)) {
                showFlashToast({
                  title: t("components.form.error.file-too-big", {
                    sizeInMegaBytes: maxMegaBytes,
                  }),
                  type: "error",
                });

                return;
              }

              addImages(images);
            }}
            imageUploadTooltip={
              !sessionUser.chatEnabled ? t("page.chat-detail.chat.disabled-input-tooltip") : undefined
            }
            sendTooltip={
              !sessionUser.chatEnabled
                ? t("page.chat-detail.chat.disabled-input-tooltip")
                : t("page.chat-detail.chat.input-tooltip")
            }
            disabled={!sessionUser.chatEnabled || !canChat}
          />
        </form>
      </div>
    </div>
  );
}
