import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { QuestionRequest } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Modal } from "components/Modal/Modal";
import { useBool } from "hooks/useBool";
import type React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface AskAiModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface AskAiModalFormValues {
  question: string;
}

export function AskAiModal({ isOpen, onClose }: AskAiModalProps): React.ReactNode {
  const [currQuestion, setCurrQuestion] = useState<string | undefined>(undefined);
  const [currResponse, setCurrResponse] = useState<string | undefined>(undefined);
  const [isFeatureAvailable, featureAvailabilityHandlers] = useBool(true);

  const { t } = useTranslation();
  const formMethods = useForm<AskAiModalFormValues>({
    defaultValues: {
      question: "",
    },
  });
  const api = useApi();

  const {
    mutateAsync: postQuestion,
    isPending: isPendingPostQuestion,
    isError: isErrorPostQuestion,
  } = useMutation({
    mutationFn: (data: QuestionRequest) => api.postProjectSummaryQuestionV1(data),
    onSuccess: (response) => {
      if (response.data === null) {
        featureAvailabilityHandlers.setFalse();
      } else {
        setCurrQuestion(formMethods.getValues("question"));
        setCurrResponse(response.data);
        formMethods.reset();
      }
    },
  });

  useEffect(() => {
    return () => {
      formMethods.reset();
      setCurrQuestion(undefined);
      setCurrResponse(undefined);
    };
  }, [formMethods, isOpen]);

  const onSubmit = (data: AskAiModalFormValues) => {
    void postQuestion(data);
  };

  return (
    <Modal onRequestClose={onClose} {...{ isOpen }}>
      <Form
        className="relative flex flex-col items-center gap-6 px-6 py-8 md:w-[520px] lg:w-[640px]"
        {...{ formMethods, onSubmit }}
      >
        <div className="flex w-full items-center justify-center gap-2">
          <FormField label={t("component.ask-ai-modal.question-input.label")} htmlFor="question" hideLabel>
            <FormInput
              className="w-full"
              id="question"
              name="question"
              placeholder={t("component.ask-ai-modal.question-input.placeholder")}
              rules={{
                required: t("components.form.error.required", {
                  inputName: t("component.ask-ai-modal.question-input.label"),
                }),
              }}
            />
          </FormField>
          <Button type="submit" disabled={isPendingPostQuestion}>
            {t("component.ask-ai-modal.ask.btn")}
          </Button>
        </div>
        <div className="flex min-h-20 w-full flex-1 items-center justify-center">
          {isPendingPostQuestion && <FullSizeLoader />}
          {!isPendingPostQuestion && !isErrorPostQuestion && currResponse && (
            <div className="flex w-full flex-col gap-2">
              <h4 className="text-headline4 font-bold leading-headline4">{currQuestion}</h4>
              <p>{currResponse}</p>
            </div>
          )}
          {!isPendingPostQuestion && isFeatureAvailable && !currResponse && (
            <p className="text-grey-dark">{t("component.ask-ai-modal.description")}</p>
          )}
          {!isPendingPostQuestion && !isFeatureAvailable && (
            <p className="text-red-dark">{t("component.ask-ai-modal.no-data.notification")}</p>
          )}
        </div>
      </Form>
    </Modal>
  );
}
