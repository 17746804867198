import { useInfiniteQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useConfig } from "hooks/useConfig";
import { useSignalRHub, useSignalRSubscription } from "hooks/useSignalR";
import { QUERY_KEYS } from "query-keys";
import { useCallback } from "react";

import type { ChatsListLayoutProps } from "./Layout";

interface ChatListLoaderProps {
  children: (props: ChatsListLayoutProps) => React.ReactNode;
}

const CHATS_PAGE = 10;

export function Loader(props: ChatListLoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const sessionUser = useSessionUser();
  const { setting: isGroupChatsAvailable } = useConfig("useGroupChats");

  const {
    data: chatsData,
    hasNextPage: hasMoreChats,
    refetch: refetchChats,
    fetchNextPage: fetchMoreChats,
    isPending: isLoadingChats,
    isFetchingNextPage: isFetchingMoreChats,
    error: chatsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.CHATS_LIST(projectId),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getChatsV2({ Offset: pageParam * CHATS_PAGE, Limit: CHATS_PAGE })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    getPreviousPageParam: () => {
      return 0;
    },
  });

  const onNewChatMessage = useCallback(async () => {
    await refetchChats();
  }, [refetchChats]);

  const { signalRConnection } = useSignalRHub("notification-hub", {
    query: `userId=${sessionUser.id}`,
    disabled: !isGroupChatsAvailable,
  });
  useSignalRSubscription(signalRConnection, "NewChatMessageNotification", onNewChatMessage);
  useSignalRSubscription(signalRConnection, "NewGroupChatCreatedNotification", onNewChatMessage);

  const chats = chatsData?.pages.flatMap((page) => page.items) || [];

  if (chatsError) {
    return <ErrorPage error={chatsError} />;
  }

  return props.children({
    chats,
    hasMoreChats,
    fetchMoreChats,
    isLoadingChats,
    isFetchingMoreChats,
  });
}
