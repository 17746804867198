import { Icon } from "components/Icon/Icon";
import type React from "react";

type NoticeProps = {
  icon?: string;
  "data-testid"?: string;
} & (
  | {
      message: string;
      customBody?: never;
    }
  | {
      message?: never;
      customBody: React.ReactNode;
    }
);

export function Notice({ icon, message, customBody, "data-testid": dataTestId }: NoticeProps): React.ReactNode {
  return (
    <div
      className="flex w-full items-start gap-4 rounded-lg border border-blue-dark bg-blue-lightest p-4"
      data-testid={dataTestId}
    >
      {icon && <Icon name={icon} size={24} className="py-1 text-blue-dark" />}
      {message && <p className="text-capture1 text-blue-dark">{message}</p>}
      {customBody}
    </div>
  );
}
