import { autoUpdate, FloatingPortal, useFloating } from "@floating-ui/react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSidebarManager } from "hooks/useSidebar";
import type React from "react";
import { forwardRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { tv } from "tailwind-variants";

import { sidebarFloatingElementAnimation, sidebarItemLabelAnimation } from "./animations";
import { type SidebarBaseItemType } from "./types";

interface SidebarItemProps extends Omit<SidebarBaseItemType, "permission" | "type"> {
  isLabelVisible?: boolean;
  isBig?: boolean;
  tabIndex?: number;
}

export function SidebarItem({
  label,
  to,
  exact,
  icon,
  "data-testid": dataTestId,
  newEventCount,
  isLabelVisible,
  isBig,
  isNewEventAvailable,
  onClick,
}: SidebarItemProps): React.ReactNode {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isTooltipVisible, tooltipVisibilityHandlers] = useBool(false);

  const { isCollapsed } = useSidebarManager();
  const isDesktop = useScreenIsBiggerThan("md");
  const { refs, floatingStyles } = useFloating({ whileElementsMounted: autoUpdate, placement: "right" });

  const showLabel = ({ withDelay }: { withDelay: boolean }) => {
    if (withDelay) {
      const timer = setTimeout(() => {
        tooltipVisibilityHandlers.set(true);
      }, 300);

      setTimer(timer);
    } else {
      tooltipVisibilityHandlers.set(true);
    }
  };

  const hideLabel = () => {
    if (timer) {
      clearTimeout(timer);
    }

    tooltipVisibilityHandlers.set(false);
  };

  return (
    <>
      <li className="w-full list-none">
        <NavLink
          className={twResolve(
            sidebarItemVariants({
              size: isBig ? "lg" : "md",
            }),
          )}
          end={exact}
          to={to}
          data-testid={dataTestId}
          onMouseEnter={isCollapsed ? () => showLabel({ withDelay: true }) : undefined}
          onMouseLeave={isCollapsed ? hideLabel : undefined}
          ref={refs.setReference}
          // Required for screenreader when sidebar is collapsed
          aria-label={label}
          {...{ onClick }}
        >
          <div className="flex items-center gap-2">
            {icon && (
              <div className="relative">
                {icon}
                {/* Notification indicator */}
                {isNewEventAvailable && (
                  <span className="absolute right-0 top-0 size-1.5 -translate-y-1/3 translate-x-1/3 rounded-full bg-red" />
                )}
              </div>
            )}
            <AnimatePresence>
              {(!isCollapsed || isLabelVisible) && (
                <motion.span
                  className={twJoin("block whitespace-nowrap")}
                  variants={sidebarItemLabelAnimation}
                  initial={isDesktop && !isLabelVisible ? "hidden" : "visible"}
                  animate="visible"
                  exit="hidden"
                >
                  {label}
                </motion.span>
              )}
            </AnimatePresence>
          </div>

          {!isCollapsed && isNewEventAvailable && !!newEventCount && newEventCount > 0 && (
            <span className="flex size-6 items-center justify-center rounded-full bg-grey-lightest !text-sm font-normal group-[[aria-current]]:bg-aop-basic-blue/10 group-hocus:bg-aop-basic-blue/10">
              {newEventCount}
            </span>
          )}
        </NavLink>
      </li>

      {/* Floating tooltip */}
      <AnimatePresence>
        {!isLabelVisible && isTooltipVisible && (
          <FloatingPortal>
            <SidebarItemFloatingTooltip
              label={label}
              ref={refs.setFloating}
              style={floatingStyles}
              isVisible={isTooltipVisible}
            />
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  );
}

interface SidebarItemFloatingTooltipProps {
  label: string;
  style: React.CSSProperties;
  isVisible: boolean;
}

// eslint-disable-next-line react/display-name
export const SidebarItemFloatingTooltip = forwardRef<HTMLSpanElement, SidebarItemFloatingTooltipProps>(
  ({ label, style }, ref) => {
    return (
      <motion.span
        className={twJoin(
          "pointer-events-none z-40 ml-2 whitespace-nowrap rounded-lg bg-black p-2 font-semibold text-white",
        )}
        variants={sidebarFloatingElementAnimation}
        initial="hidden"
        animate="visible"
        exit="hidden"
        {...{ ref, style }}
      >
        {label}
      </motion.span>
    );
  },
);

export const sidebarItemVariants = tv({
  base: [
    "relative flex h-10 items-center justify-between rounded-lg p-3 transition-colors duration-200 ease-linear",
    "hover:bg-aop-basic-blue-lightest focus:outline-none focus-visible:bg-aop-basic-blue-lightest",
    "[&.active]:bg-aop-basic-blue-lightest [&.active]:font-semibold [&.active]:text-aop-basic-blue",
  ],
  variants: {
    size: {
      md: "[&_span]:text-subtitle2 [&_span]:font-medium",
      lg: "[&_span]:text-headline4 [&_span]:font-semibold",
    },
    isActive: {
      true: "bg-aop-basic-blue-lightest font-semibold text-aop-basic-blue",
      false: "font-medium",
    },
  },
  defaultVariants: {
    isActive: false,
    size: "md",
  },
});
