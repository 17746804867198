import type { IConfigCatClient, SettingTypeOf, User } from "configcat-common";
import { PollingMode } from "configcat-common";
import { DataGovernance, getClient } from "configcat-js";

export type ConfigCatUser = User;

export const ProjectIdCustomKey = "ProjectId";

export function getFromConfig<TKey extends keyof Config>(
  key: TKey,
  defaultValue?: Config[TKey],
  user?: ConfigCatUser,
): Promise<SettingTypeOf<Config[TKey]> | undefined> {
  return configClient.getValueAsync(key, defaultValue, user) as any;
}

export function getFromConfigSnapshot<TKey extends keyof Config>(
  key: TKey,
  defaultValue?: Config[TKey],
  user?: ConfigCatUser,
): SettingTypeOf<Config[TKey]> | undefined {
  return configClient.snapshot().getValue(key, defaultValue, user) as any;
}

export interface Config {
  newCoreApiRootUri: string;
  newDashboardRootUrl: string;
  azureAdB2cInstance: string;
  azureAdB2cDomain: string;
  azureAdB2cNewDashboardClientId: string;
  azureAdB2cCoreApiScope: string;
  azureAdB2cDashboardSignInPolicyId: string;
  azureAdB2cPoliciesSignUpV2: string;
  azureApplicationInsightsInstrumentationKey: string;
  envLongName: string;
  pusherDashboardAppKey: string;
  intercomDashboardAppId: string;
  preprDashboardUrl: string;
  enablePortfolioEngagementStat: boolean;
  allowRealEstateGroups: boolean;
  allowBookings: boolean;
  useNewCardLayout: boolean;
  enableNewSurveyExport: boolean;
  useSignalR: boolean;
  showPortfolioTicketList: boolean;
  showTicketPortfolioSettings: boolean;
  showAvailableIntegrations: boolean;
  useGroupChats: boolean;
  hasChatsV2Migrated: boolean;
  isAiToolingAvailable: boolean;
}

let configClient: {
  getValueAsync: IConfigCatClient["getValueAsync"];
  snapshot: () => Pick<ReturnType<IConfigCatClient["snapshot"]>, "getValue">;
};

const developmentConfig: {
  [T in keyof Config]: Config[T] | undefined;
} = {
  newCoreApiRootUri: import.meta.env.VITE_API_BASE_URL,
  newDashboardRootUrl: import.meta.env.VITE_DASHBOARD_ROOT_URL,
  azureAdB2cInstance: import.meta.env.VITE_AZURE_AD_INSTANCE,
  azureAdB2cDomain: import.meta.env.VITE_AZURE_AD_DOMAIN,
  azureAdB2cNewDashboardClientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID,
  azureAdB2cCoreApiScope: import.meta.env.VITE_AZURE_AD_SCOPE,
  azureAdB2cDashboardSignInPolicyId: import.meta.env.VITE_AZURE_AD_SIGN_IN_POLICY_ID,
  azureAdB2cPoliciesSignUpV2: import.meta.env.VITE_AZURE_AD_SIGN_UP_POLICY_ID,
  azureApplicationInsightsInstrumentationKey: undefined,
  envLongName: import.meta.env.VITE_MOCK_API_IN_DEVELOPMENT === "true" ? "Mock" : "Local",
  pusherDashboardAppKey: import.meta.env.VITE_PUSHER_APP_KEY,
  intercomDashboardAppId: import.meta.env.VITE_INTERCOM_APP_ID,
  preprDashboardUrl: import.meta.env.VITE_PREPR_DASHBOARD_URL,
  enablePortfolioEngagementStat: true,
  allowRealEstateGroups: true,
  allowBookings: true,
  useNewCardLayout: true,
  enableNewSurveyExport: true,
  useSignalR: true,
  showPortfolioTicketList: true,
  showTicketPortfolioSettings: true,
  showAvailableIntegrations: true,
  useGroupChats: true,
  hasChatsV2Migrated: true,
  isAiToolingAvailable: true,
};

if (import.meta.env.DEV || import.meta.env.MODE === "test" || import.meta.env.STORYBOOK === "true") {
  configClient = {
    getValueAsync: (key: keyof Config) => Promise.resolve(developmentConfig[key] as any),
    snapshot: () => ({
      getValue: (key: keyof Config) => developmentConfig[key] as any,
    }),
  };
} else {
  // Use dynamic config cat key configuration in non development environments
  const configCatSDKKeyProperty = "AOP_CONFIGCAT_KEY";

  if (typeof window[configCatSDKKeyProperty] !== "string" || !window[configCatSDKKeyProperty]) {
    throw new Error(
      `Configuration Problem: Incorrect configcat SDK key. In production the configcat SDK key for the API has to be set on the window instance at 'window.${configCatSDKKeyProperty}' as string, found '${window[configCatSDKKeyProperty] as any}'.`,
    );
  }

  configClient = getClient(window[configCatSDKKeyProperty], PollingMode.LazyLoad, {
    dataGovernance: DataGovernance.EuOnly,
    cacheTimeToLiveSeconds: 600,
  });
}
