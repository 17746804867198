import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useImageResolver } from "api/hooks/useImageResolver";
import type { EventDetailsDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconPlus from "assets/icons/plus.svg";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { Badge } from "components/Badge/Badge";
import { Button } from "components/Button/Button";
import { ToggleButton } from "components/Button/ToggleButton";
import { DateRange } from "components/DateRange/DateRange";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Icon54 } from "components/Icon54/Icon54";
import { Label } from "components/Label/Label";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { TagList, transformConstraintToTag } from "components/Tag/TagList";
import { Headline4, Subtitle2 } from "components/Text/Text";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { isPast, parseISO } from "date-fns";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useResolvedPermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { EventStatus } from "modules/events/pages/Details/components/EventStatus";
import { canCreate as canCreatePermissionSelector } from "modules/events/permissions";
import { getEventCategoryName } from "modules/events/util";
import { QUERY_KEYS } from "query-keys";
import {
  Calendar as CalendarIcon,
  Copy as CopyIcon,
  Edit as EditIcon,
  MapPin as MapPinIcon,
  Trash2 as Trash2Icon,
  User as UserIcon,
  Users as UsersIcon,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface EventDetailsProps {
  eventDetails: EventDetailsDto;
}

export function EventDetails(props: EventDetailsProps): React.ReactNode {
  const projectId = useProjectId();
  const {
    eventDetails: {
      isSignedUp,
      image,
      id,
      startsAt,
      endsAt,
      organizer,
      location,
      audience,
      category,
      description,
      canEdit,
      canDelete,
      canParticipate,
      projectConnection,
      deletedAt,
    },
  } = props;
  const slug = useSlug();
  const user = useSessionUser();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const { componentProps, openDeleteModal } = useDeleteModal<{ id: string }>("event-delete-modal");
  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();
  const { mutate: signUp, isPending: isSigningUp } = useMutation({
    mutationFn: () => api.postEventsSignupV1(id),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_DETAILS(projectId, id) });
    },
  });
  const { mutate: signOff, isPending: isSigningOff } = useMutation({
    mutationFn: () => api.postEventsSignoffV1(id),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_DETAILS(projectId, id) });
    },
  });
  const { mutateAsync: deleteEvent, isPending: isDeleting } = useMutation({
    mutationFn: () => api.deleteEventsByIdV1(id),
    onSuccess: () => {
      navigate(routes.calendar.list({ slug }));
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.EVENT_LIST_ALL(projectId), refetchType: "none" });
    },
  });
  const { data: eventCategories = [] } = useQuery({
    queryKey: QUERY_KEYS.EVENT_CATEGORIES(projectId),
    queryFn: () => api.getEventsCategoriesV1(),
    select: commonAPIDataSelector,
  });
  const resolveImage = useImageResolver();
  const categoryDetails = category ? eventCategories.find(({ id }) => id === category?.id) : undefined;

  const canCreate = useResolvedPermission(canCreatePermissionSelector);

  const resolvedImage = image ?? user.project.backgroundImage;

  return (
    <div>
      <div className="relative rounded-sm">
        <div className="w-full group-hover:brightness-90">
          <div
            className="bg-cover bg-center pb-[66%]"
            style={{
              backgroundImage: `url(${resolvedImage ? resolveImage(resolvedImage, "large") : bgPlaceholder})`,
            }}
          />
        </div>
        <div className="absolute bottom-0 right-8 flex size-24 translate-y-1/2 items-center justify-center rounded-sm border border-grey-lighter bg-white p-4">
          <Icon54 char={categoryDetails?.iconChar} data-testid="event-icon" disableUserSelect />
        </div>
      </div>
      <div className="p-2">
        <div>
          <div className="float-right h-12 w-32" />
          {!props.eventDetails.publishedAt ? (
            <div className="my-2">
              <Label theme="blue">
                <div className="flex gap-1 whitespace-nowrap">
                  {t("model.event.details.scheduled")}
                  <FormattedDate date={props.eventDetails.publishAt} format="datetimeShort" />
                </div>
              </Label>
            </div>
          ) : null}
          <Headline4 className="break-all leading-8" as="h2">
            {props.eventDetails.name}
          </Headline4>
          <div className="mb-2 text-grey-dark">
            <EventStatus {...props.eventDetails} />
          </div>
          <p className="mb-4">
            <LinkFormatter>{description}</LinkFormatter>
          </p>
        </div>
        <div className="mb-6">
          {deletedAt ? (
            <Label theme="red">{t("model.event.details.deleted")}</Label>
          ) : (
            <ToggleButton
              isLoading={isSigningOff || isSigningUp}
              isChecked={isSignedUp}
              config={{
                checked: {
                  label: t("model.event.details.action.sign-off"),
                  icon: <Icon name={iconCheck} size={16} className="shrink-0" />,
                },
                unchecked: {
                  label: t("model.event.details.action.sign-up"),
                  icon: <Icon name={iconPlus} size={16} className="shrink-0" />,
                },
              }}
              disabled={!(isSignedUp || canParticipate) || isPast(parseISO(endsAt))}
              data-testid="event-participation-toggle"
              onClick={() => {
                if (isSignedUp) {
                  signOff();
                } else {
                  signUp();
                }
              }}
            />
          )}
        </div>
        <article className="mb-6 flex flex-col items-start gap-2">
          <Subtitle2 as="h3">{t("model.event.details.general")}</Subtitle2>
          <div className="flex items-center gap-2">
            <CalendarIcon className="shrink-0 text-grey" size={16} />
            <DateRange start={startsAt} end={endsAt} />
          </div>
          {location != null && (
            <div className="flex items-center gap-2">
              <MapPinIcon className="shrink-0 text-grey" size={16} />
              {location}
            </div>
          )}
          <div className="flex items-center gap-2">
            <UserIcon className="shrink-0 text-grey" size={16} />
            <UserNameLink date-testid="event-organizer-link" user={organizer} asAnchor>
              {organizer.fullName}
            </UserNameLink>
          </div>
          <div className="flex gap-2">
            {categoryDetails ? <Badge selected>{getEventCategoryName(t, categoryDetails.id)}</Badge> : null}
            {projectConnection && <Badge>{projectConnection.name}</Badge>}
          </div>
        </article>
        <article className="mb-6 flex flex-col gap-2">
          <Subtitle2 as="h3">{t("model.event.details.audience")}</Subtitle2>
          {audience.length ? (
            <TagList tags={audience.map(transformConstraintToTag)} limit={3} />
          ) : (
            <div className="flex items-center gap-1 text-grey-darker">
              <UsersIcon size={12} />
              <i>{t("model.event.details.audience.no-restrictions")}</i>
            </div>
          )}
        </article>

        <div className="flex flex-wrap gap-x-1 gap-y-2 text-grey-darker sm:gap-x-6">
          {canEdit && (
            <Button
              styling="tertiary"
              className="flex gap-1"
              data-testid="event-edit-btn"
              onClick={() => {
                if (isPast(parseISO(startsAt))) {
                  showFlashToast({
                    type: "info",
                    title: t("model.event.action.edit.notification.is-started"),
                  });

                  return;
                }

                navigate(routes.events.edit({ slug, id }));
              }}
            >
              <EditIcon size={16} />
              {t("common.action.edit")}
            </Button>
          )}
          {canCreate && (
            <Button
              styling="tertiary"
              className="flex gap-1"
              data-testid="event-duplicate-btn"
              onClick={() => {
                navigate(routes.events.create({ slug }), { state: { id: props.eventDetails.id } });
              }}
            >
              <CopyIcon size={16} />
              {t("common.action.duplicate")}
            </Button>
          )}
          {canDelete && (
            <Button
              className="flex gap-1"
              data-testid="event-delete-btn"
              onClick={() => {
                openDeleteModal({ id });
              }}
              styling="danger"
              isLoading={isDeleting}
            >
              <Trash2Icon size={16} />
              {t("common.action.delete")}
            </Button>
          )}
        </div>
      </div>
      <DeleteModal
        title={t("model.event.action.delete.confirmation")}
        description={t("model.event.action.delete.description")}
        onDelete={() => deleteEvent()}
        {...componentProps}
      />
    </div>
  );
}
