import { twResolve } from "helpers/tw-resolve";

interface Props {
  "data-testid"?: string;
  theme: "red" | "yellow" | "green" | "aopDarkBlue" | "blue" | "gray" | "purple";
  size?: "normal" | "small" | "big";
  center?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

export function Label({
  theme,
  size,
  center = false,
  onClick,
  children,
  "data-testid": dataTestId,
}: Props): React.ReactNode {
  const Component = onClick ? "button" : "span";

  return (
    <Component
      className={twResolve(
        "inline-flex h-8 items-center gap-1.5 rounded p-2 text-[14px] font-semibold uppercase leading-none",
        getStyling(theme),
        onClick ? "transition-all hocus:brightness-75" : undefined,
        size === "small" && "h-auto p-1 text-[10px]",
        size === "big" && "h-auto text-[14px] font-semibold",
        center ? "self-center" : "self-start",
      )}
      data-testid={dataTestId}
      onClick={onClick}
      type={onClick ? "button" : undefined}
    >
      {children}
    </Component>
  );
}

function getStyling(theme: Props["theme"]) {
  switch (theme) {
    case "red":
      return "bg-red-dark/10 text-red-dark";
    case "yellow":
      return "bg-yellow-dark/10 text-yellow-dark";
    case "green":
      return "bg-green-dark/10 text-green-dark";
    case "aopDarkBlue":
      return "bg-aop-dark-blue/10 text-aop-dark-blue";
    case "blue":
      return "bg-blue-dark/10 text-blue-dark";
    case "gray":
      return "bg-grey-darker/10 text-grey-darkest";
    case "purple":
      return "bg-aop-bright-purple/10 text-aop-bright-purple";
    default:
      throw new Error(`Unknown theme: ${theme}`);
  }
}
